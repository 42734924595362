import React from "react"

import { Link } from "gatsby"
import Layout from "../layout/layout"
import Button from "../components/button"
import ImageGroup from "../components/contactImageGroup"

const HowYouCanStudy = () => {
  return (
    <Layout
      title="Enrolment Forms for Domestic & international "
      description="Domestic & international Enrollment Forms"
    >
      <div className="layout-container study-page-container">
        <div className="study-page-content">
          <h3 style={{ marginBottom: "20px" }}>To enrol</h3>
          <div className="className=" study-page-p>
            <p>
              Summit Institute's goal is to provide a bridge to success. So, tell us
              what you need and then we will make it happen as best we can and
              in the manner that suits your needs and meets NZQA requirements.
            </p>
            <br />
            <p>
              To enrol, you must complete the learner enrolment form applicable
              to your situation, and submit via email to{" "}
              <a
                href="mailto:info@summitinstitute.ac.nz?subject=Summit Institute Enrolment and Application Form Submission"
                className="info-link"
              >
                info@summitinstitute.ac.nz
              </a>{" "}
              along with the required information and indentification. Please
              make sure you have looked at what is required before starting to
              enrol.
            </p>
            <br />
            <p>
              If you have questions, please{" "}
              <Link to="/contact" className="info-link">
                contact us.
              </Link>
            </p>
            <br />
          </div>
          <div className="hide-mobile">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                margin: "20px 0px",
              }}
            >
              <a
                href="https://forms.gle/X7AZkWvV2gRbi1AVA"
                style={{ marginBottom: "20px" }}
              >
                <Button>NZ Domestic Enrolments</Button>
              </a>
              <a
                href="https://forms.gle/aCyvGtFrQFuLTp41A"
              >
                <Button>International Enrolment Form</Button>
              </a>
            </div>
          </div>
          <br />
        </div>
        <ImageGroup></ImageGroup>
        <div className="only-mobile">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <a
              href="https://forms.gle/X7AZkWvV2gRbi1AVA"
              style={{ marginBottom: "10px" }}
            >
              <Button>NZ Domestic Enrolments</Button>
            </a>
            <a href="https://forms.gle/aCyvGtFrQFuLTp41A">
              <Button>International Enrolment Form</Button>
            </a>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default HowYouCanStudy
